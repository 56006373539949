@import "../../variables";

.small {
    &.smaller {
        font-size: 0.775rem !important;
    }
}

.text-secondary, .text-dust{
    a:not([class*="btn-"]):not([class*="cta-btn"]):not([class*="circle-inner-link"]){
        color:$secondary;
        &:hover{
            color:$secondary!important;
        }
    }
}

.links-tertiary{
    a{
        color:$tertiary;
        font-weight: bold;
    }
}