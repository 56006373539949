@import "../../variables.scss";
.team-profile {
    position:relative;
    margin: $spacer ($spacer * 2.5);
    &-inner {
        padding-top:100%; // should make it square
    }
    &-bg {
        circle {
            fill: $secondary;
        }
        &.circle-premier {
            circle {
                fill: $premier !important;
            }
        }
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    &-image {
        width:100%;
        height: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: bottom center;
        mask-position: bottom center;
        -webkit-mask-image: url("images/headshot-mask.svg");
        mask-image: url("images/headshot-mask.svg");
        //transform: translateY(20%);
    }
}

@each $name, $colour in $brand-colors {
    .team-profile-#{$name} {
        .team-profile-bg {
            circle {
                fill: #{$colour};
            }
        }
    }
}

.spokesperson {
    .team-profile {
        margin: 0!important;
    }

    &-premier {
        @include transition($nav-link-transition);
        &:hover {
            color: $premier !important;

        }
    }
    &-private {
        @include transition($nav-link-transition);
        &:hover {
            color: $private !important;

        }
    }
}

@include media-breakpoint-up(md) {

.spokesperson-inline{
    .spokesperson{
        display: inline-block;
        margin-right: $spacer;
        .col-4,.col-8{
            width: 100%!important;
        }
        .col-4{
            margin-bottom: $spacer!important;
        }
        .team-profile{
            width: 150px!important;
        }
    }
}
}

.blog-author {
    h6, p {
        color: $secondary;
    }
    h6 {
        @extend .mb-1;
    }
    p {
        @extend .mb-0;
    }

    .team-profile-bg circle {
        fill: $secondary !important;
    }

    .spokesperson {
        width: 70%;
        @include media-breakpoint-down(lg) {
            width: 45%;
        }
        @include media-breakpoint-down(md) {
            width: 70%;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        &-premier {
            a:hover {
                color: $premier !important;
            }
        }
        &-private {
            a:hover {
                color: $private !important;
            }
        }
    }
}

.team-tab.nav-link {
    @include media-breakpoint-down(lg) {
        border-radius: 50rem;
        background-color: $secondary!important;
        margin-bottom: 1rem!important;
        width: 100%;
        padding-top: $btn-padding-y!important;
        padding-bottom: $btn-padding-y!important;
        font-size: 1rem!important;
        line-height: 1.5rem!important;
        &.active {
            background-color: $premier!important;
            color: $secondary!important;
        }
    }
    @include media-breakpoint-up(lg) {
        width: 20%;
    }
}

.tab-content-wrapper {
    @include media-breakpoint-up(lg) {
        background: $secondary;
    }
}

.profile-more{
    .arrow-link-down{
        text-decoration: none;
        @include transition($nav-link-transition);
        &:hover{
            color:$premier!important;
            &::after {
                background:$premier!important;
                transform: translateY(10px) rotate(90deg);
            }
        }
        &.active {
            &::after {
                transform: rotate(-90deg);
            }
            &:hover{
                &::after {
                    transform: translateY(-10px) rotate(-90deg);
                }
            }
        }
    }
    &-inner{
        max-height:0;
        overflow: hidden;
        transition: max-height $homepage-card-duration ease-in-out;
        &.active{
            max-height:10000px;
        }
        a{
            @include transition($nav-link-transition);
            &:hover{
                color:$premier!important;
            }
        }
    }
}

.profile-social{
    background:$primary!important;
    height: $h3-font-size;
    width: $h3-font-size;
    &:hover{
        background:$premier!important;
    }
    display: inline-block;
}

//main team tabbed page styles 
.team-panel{
    h4,p{
        color:$primary!important;
    }
    .team-profile{
        transition: transform 500ms ease;
        transform: scale(1.01);
    }
    &:hover {
        .team-profile{
            transform: scale(1.03);
        }
        .icon-arrow-right{
            background-color: $premier!important;
        }
    }
    .team-profile{
        margin:$spacer!important;
    }
    &-private{
        &:hover {
            color:$private;
            .icon-arrow-right{
                background-color: $private!important;
            }
        }
    }
    &-asset{
        &:hover {
            color:$asset;
            .icon-arrow-right{
                background-color: $asset!important;
            }
        }
    }
    &-lorica{
        &:hover {
            color:$lorica;
            .icon-arrow-right{
                background-color: $lorica!important;
            }
        }
    }
}