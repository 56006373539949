@import '../variables';

header.position-fixed {
    // z-index: 1000;
    // @include media-breakpoint-up(lg){
        z-index: 10000;
    //}
    top: 0;
    left: 0;
    right: 0;
}
// #main-nav {
//     transition: none!important;
//     &.show {
//         // when shown positioned absolute and takes up whole screen (like the modals)
//         @include media-breakpoint-down(lg){
//             position: fixed;
//             top: 0;
//             left: 0;
//             right: 0;
//             bottom: 0;
//             background-color: $primary;
//         }
//     }
// }
#call-to-action-modal, .z-10001 {
    z-index: 10001;
}


.navbar-brand {
    background: $secondary;
    -webkit-mask-image: url("images/saltus-logo-dust.svg");
    mask-image: url("images/saltus-logo-dust.svg");
    width: $logo-width;
    height: $logo-height;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center right;
    mask-position: center right;
    @include transition($nav-link-transition);
    &:hover {
        background: $premier;
    }
    &-gold{
        background: $primary;
        &:hover {
            background: $secondary;
        }
    }
}

.navbar-login {
    display: flex;
    align-items: center;
    &::after, span:before {
        content: '';
        display: block;
        margin-top: $spacer*0.25;
        background: $secondary;
        height: 1.2rem;
        width: 0.9rem;
        -webkit-mask-image: url("images/login.svg");
        mask-image: url("images/login.svg");
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: top;
        mask-position: top;
        -webkit-mask-size: contain;
        mask-size: contain;
        margin-left: $spacer *0.75;
        @include transition($nav-link-transition);
    }
    span:before{
        display: inline-block;
        vertical-align: bottom;
        margin-left: 0;
        margin-right: $spacer *0.5;
        @include media-breakpoint-up(lg){
            display: none;
        }

    }
    &:hover {
        &::after {
            background: $premier;
        }
    }
    &.active {
        &::after {
            background: $navbar-dark-active-color;
        }
    }
}

.navbar-contact {
    display: flex;
    align-items: center;
    position: relative;
    .cta-phone::after, span:before {
        content: '';
        display: block;
        background: $secondary;
        height: 1.2rem;
        width: 0.9rem;
        margin-top: $spacer*0.25;
        -webkit-mask-image: url("images/contact.svg");
        mask-image: url("images/contact.svg");
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: top;
        mask-position: top;
        -webkit-mask-size: contain;
        mask-size: contain;
        margin-left: $spacer *0.5;
        @include transition($nav-link-transition);
    }
    span:before{
        display: inline-block;
        vertical-align: bottom;
        margin-left: 0;
        margin-right: $spacer *0.5;
        @include media-breakpoint-up(lg){
            display: none;
        }

    }
    &.nav-link{
        &:hover,&.active,&:active,&:focus {
            color:$secondary!important;
        }
    }
    &.active{
        
        background: transparent!important;
    }
}

// style buttons used in navbar
.navbar-nav {
    .btn.rounded-pill {
        font-size: $h6-font-size;
        &:hover {
            border-color: $premier;
            background-color: transparent!important;
            color: $premier;
        }
    }
}

.navbar-spp{
    display: flex;
    align-items: center;
    text-decoration: none;
    span:not(.icon-arrow-back){
        color: $primary;
        font-size: $font-size-sm;
        @include transition(opacity .15s ease-in-out);
        text-decoration: none; 
        
    }
    .icon-arrow-back{
        height: 1rem;
        width: 1rem;
    }
    &:hover{
        span{
            color: $secondary;
        }
        .icon-arrow-back{
            background: $secondary!important;
        }
    }
}


.navbar-home {
    display: flex;
    align-items: center;
    text-decoration: none;
    &::before {
        content: '';
        display: block;
        background: $secondary;
        height: 1.2rem;
        width: 1.2rem;
        -webkit-mask-image: url("images/home.svg");
        mask-image: url("images/home.svg");
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: top;
        mask-position: top;
        -webkit-mask-size: contain;
        mask-size: contain;
        @include transition($nav-link-transition);
    }
    &:hover,&.active {
        &::before {
            background: $premier;
        }
        span{
            opacity: 1;
        }
    }
    span{
        color: $premier;
        font-size: $font-size-sm;
        opacity: 0;
        @include transition(opacity .15s ease-in-out);
        text-decoration: none;
    }
    &-gold{
        &::before {
            background:$primary;
        }
        &:hover {
            &::before {
                background: $secondary;
            }
        }
        span{
            color: $secondary;
        }
    }
}

.nav-lines {    
    // margin-left: 0;
    // padding-left: 0;
    border-top: 1px solid $secondary;
    .nav-item {
        border-bottom: 1px solid $secondary;
        list-style: none;
    }
    &-dark{
        border-top: 1px solid $dark;
        .nav-item{
            border-bottom: 1px solid $dark;
        }
    }
    .nav-link {
        padding: $nav-link-padding-y 0;
        @include transition($nav-link-translate-transition);
        &::after {
            @include transition($nav-link-translate-transition);
        }
        &:hover {
            transform: translateX($spacer);
            &::after {
                transform: translateX($spacer * -2);
            }
        }
    }
}

.modal {
    background-color: $primary;
}

.modal-nav{
    background-color: rgba($primary, .5);
    backdrop-filter: blur(0px);
    //transition-delay: 2s;
    transition: backdrop-filter 0.3s ease-in-out;
    &.show{
        backdrop-filter: blur(5px);
    }
    .modal-fullscreen .modal-content{
        height: auto!important;
    }
    .modal-content{
        -webkit-box-shadow: 0 .5rem 1rem rgba($primary,.5) !important;
        box-shadow: 0 .5rem 1rem rgba($primary,.5) !important;
    }
}

.modal-product-menu {
    .nav-item:first-child {
        font-size: $h6-font-size;
        font-family: $headings-font-family;
    }
    .nav-item:not(:first-child) a {
        padding-left: $spacer*2;
    }
}

// override standard bootstrap class
.btn-close{
    display: block;
    height: $close-size;
    width: $close-size;
    background: $secondary;
    -webkit-mask-image: url("images/close.svg");
    mask-image: url("images/close.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: top;
    mask-position: top;
    -webkit-mask-size: contain;
    mask-size: contain;
    padding: 0;
    margin: 0;
    @include transition($nav-link-transition);
    &:hover {
        background: $premier;
    }
}

// from state
#modal-close {
    width: 0;
    text-align: right;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.1s ease-in-out, width 0.2s linear 0.1s;
   // transition: 
}
#navbar-brand {
    width: $logo-width;
    overflow: hidden;
    // fade the logo out then hide
    transition: width 0s linear 0.1s, opacity 0.3s ease-in-out 0.1s;
    // then show the close and fade in
}
.navbar-toggler {
    opacity: 1;
    transition: width 0s linear 0.1s, opacity 0.3s ease-in-out 0.1s;
}

#mobile-header {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    span {
        height: $logo-height;
        line-height: $logo-height;
    }
    &-back {
        opacity: 0;
    }
    &.active {
        #mobile-header-back {
            opacity: 1;
        }
    }
    
}

// to state
.modal-menu-open {
    // #navbar-brand {
    //     width: 0;
    //     opacity: 0;
    //     transition: opacity 0.3s ease-in-out, width 0s linear 0.3s;
    // }
    // .navbar-toggler {
    //     opacity: 0;
    //     transition: opacity 0.3s ease-in-out, width 0s linear 0.3s;
    // }
   
    #mobile-header{
        z-index: 1000000000;

    }
    @include media-breakpoint-down(lg){
        #mobile-header {
            display: flex;
        }   
        header{
            z-index: 0!important;
        }
    }
    @include media-breakpoint-up(lg){
        #modal-close {
            width: $close-size;
            opacity: 1;
            transition: width 0s linear 0.3s, opacity 0.3s ease-in-out 0.3s;
        }
        #navbar-brand {
                width: 0;
                opacity: 0;
                transition: opacity 0.3s ease-in-out, width 0s linear 0.3s;
            }
    }
    .nav-link.active {
        color: $navbar-dark-color!important; // override active state when menu is open
        &.navbar-login {
            ::before {
                background: $navbar-dark-color!important; 
            }
        }
        &:hover {
            color: $navbar-dark-hover-color!important;
            &.navbar-login {
                ::before {
                    background: $navbar-dark-hover-color!important; 
                }
            }
        }
        &.modal-active {
            color: $navbar-dark-active-color!important;
        }
    }
    .modal-active {
        color: $navbar-dark-active-color!important;
    }
    .btn.rounded-pill.modal-active {
        border-color: $premier;
        &::before {
            background-color: $premier;
        }
    }
}

// closing animation
// .modal-transition-complete {
//     #navbar-brand {
//         transition: width 0s linear 0.1s, opacity 0.3s ease-in-out 0.1s;
//     }
//     #modal-close {
//         transition: opacity 0.1s ease-in-out, width 0s linear 0.1s;
//     }
// }


// create icon colours for each theme colour
@each $name, $colour in $brand-colors {
    .#{$name} {
        // only apply when we are on the dust background
        .bg-secondary {
            .arrow-link::after {
                background: #{$colour}
            }

        }
    }
}

header .nav-tabs{
    margin-left: -$spacer;
    .nav-link{
        color: $secondary;
        padding:$spacer*0.5 $spacer;
        //font-family: $headings-font-family;
        &.active{
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            background-color:$primary;
            color:$secondary;
            border: $border-width solid $premier;
            border-bottom-color: $primary!important;
        }
    }
}

.nav-lower{
    margin-top:-$border-width;
    // z-index: 10;
    // position: relative;
    border-top:$border-width $premier solid;
}

.nav-area{
    display: none;
    &:first-of-type{
        display: block;
    }
}

.side-navigation{
    ul li:last-of-type a{
        border: none!important;
    }
}