@import "../../variables";

.form-select {
    padding: $input-padding-x;
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23fff5e6%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    &.bg-secondary,&.bg-tint {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2318263a%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    }
}

.form-floating>.bg-tint+label, .form-floating>.bg-secondary+label{
    color:$input-placeholder-color;
    opacity: 1!important;
}
.form-floating>label{
    color:$secondary;
    opacity: 1!important;
}

textarea{
    height: 105px!important;
}
